<template>
  <v-main class="accounts">
    <div class="app-container pt-10 pb-3">
      <section
        class="accounts__tool-bar d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="accounts__tool-bar__info">
          <h1 class="text-h4 font-weight-bold">
            {{ pageInfo.title }}
          </h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            {{ pageInfo.description }}
          </p>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-3"
            @click="isEditModalVisible = true"
          >
            Create new account
          </v-btn>
        </div>
      </section>

      <section class="accounts__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />
        <v-data-table
          v-if="!firstLoading"
          id="accounts-table"
          :headers="tableHeaders"
          :items="accounts"
          :loading="loading"
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="accountsTotal"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          multi-sort
          @update:options="handleTableUpdate"
          @click:row="onRowClick"
        >
          <template #item.createdAt="{ item }">
            {{ dateToLocalString(item.createdAt) }}
          </template>

          <template #item.updatedAt="{ item }">
            {{ dateToLocalString(item.updatedAt) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <ListContextMenu
              v-if="!item.isProtected"
              :item="item"
              @delete="deleteAccount(item)"
            />
            <v-tooltip v-else nudge-left left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-pencil-off-outline</v-icon>
              </template>
              <span>Unremovable</span>
            </v-tooltip>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          v-model="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>
    </div>

    <EditAccountModal
      v-model="isEditModalVisible"
      :account-id="activeAccountId"
    />

    <DeleteModal
      title="Delete account"
      description="Are you sure you want to delete the account and all related resources (e.g., cases, batches, etc.)?"
      :is-opened="!!deleteAccountId"
      :loading="deleteAccountLoading"
      @submit="onDeleteAccount"
      @close="deleteAccountId = null"
    />
  </v-main>
</template>

<script>
import { getAccounts, deleteAccountById } from "@/services/accounts";
import { TableRowsPerPage } from "@/misc/constants";
import EditAccountModal from "@/components/accounts/EditAccountModal.vue";
import ListContextMenu from "@/components/accounts/ListContextMenu.vue";
import DeleteModal from "@/components/common/DeleteModal.vue";
import dateToLocalString from "@/helpers/dateToLocalString";
import { useUserStore } from "@/stores/user";
import { mapState, mapActions } from "pinia";

export default {
  name: "AccountsPage",

  components: {
    EditAccountModal,
    ListContextMenu,
    DeleteModal,
  },

  data() {
    const tableHeaders = [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Creation Date",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "Modification Date",
        value: "updatedAt",
        sortable: true,
      },
      {
        text: "",
        align: "end",
        value: "actions",
      },
    ];
    const pageInfo = {
      title: "Accounts",
      description: "Here you can create new accounts or manage existing.",
    };
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    return {
      options: {},
      accounts: [],
      accountsTotal: null,
      loading: false,
      firstLoading: true,
      pageInfo,
      tableHeaders,
      tableFooterOptions,
      isEditModalVisible: false,
      activeAccountId: null,
      deleteAccountId: null,
      deleteAccountLoading: false,
    };
  },

  computed: {
    ...mapState(useUserStore, ["currentAccountId"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.accountsTotal / this.options.itemsPerPage);
    },
  },

  watch: {
    isEditModalVisible(isVisible) {
      if (!isVisible) {
        this.activeAccountId = null;
        this.fetchAccounts();
        this.getUserAccounts();
      }
    },
  },

  async mounted() {
    await this.fetchAccounts();
  },

  methods: {
    ...mapActions(useUserStore, ["getUserAccounts"]),
    dateToLocalString,
    async fetchAccounts() {
      this.loading = true;
      try {
        const query = this.buildQuery();
        const { data, total } = await getAccounts(query);

        this.accounts = data;
        this.accountsTotal = total;
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Accounts fetch failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const query = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      query.page = page || 1;
      query.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        query.sort = sort;
      }

      return query;
    },
    onRowClick(item) {
      this.activeAccountId = item._id;
      this.isEditModalVisible = true;
    },
    async handleTableUpdate() {
      await this.fetchAccounts();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    async deleteAccount(item) {
      this.deleteAccountId = item.id;
    },
    async onDeleteAccount() {
      if (!this.deleteAccountId || this.deleteAccountLoading) return;
      this.deleteAccountLoading = true;
      try {
        await deleteAccountById(this.deleteAccountId);

        this.deleteAccountId = null;
        this.deleteAccountLoading = false;
        // if we're in the deleted account - logout
        this.$notify({ type: "success", text: "Account deleted" });

        // logout if we're in the deleted account
        if (
          this.deleteAccountId &&
          this.deleteAccountId === this.currentAccountId
        ) {
          return this.$auth.logout();
        }

        // handle error here as it's not related to the account removal
        await this.fetchAccounts().catch((err) => {
          console.error(err);
        });
      } catch (err) {
        this.deleteAccountId = null;
        this.deleteAccountLoading = false;
        return this.$notify({
          type: "error",
          text: err?.response?.data?.message || "Cannot delete account",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

.v-tooltip__content {
      position: absolute !important;
}

.accounts {
  &__tool-bar {
    &-filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }
}
</style>
