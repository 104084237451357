import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/accounts";

const extractData = (response) => response.data;

export const getAccounts = (params = {}) =>
  axiosInstance.get(endpointUrl, { params }).then(extractData);

export const getAccountById = (id, params = {}) =>
  axiosInstance.get(`${endpointUrl}/${id}`, { params }).then(extractData);

export const createAccount = (payload) =>
  axiosInstance.post(endpointUrl, payload).then(extractData);

export const updateAccountById = (id, payload) =>
  axiosInstance.patch(`${endpointUrl}/${id}`, payload).then(extractData);

export const deleteAccountById = (id) =>
  axiosInstance.delete(`${endpointUrl}/${id}`).then(extractData);
